export function getAmenityAlias(amenity: string) {
  return amenityMap[amenity] || amenity
}

export const amenityMap: Record<string, string> = {
  'Short Term Available': 'Short-Term Available',
  'Basketball Court(s)': 'Basketball Court',
  'Cable Ready': 'TV Cable Ready',
  'Ceiling Fan(s)': 'Ceiling Fans',
  'Climate Controlled': 'Climate-Controlled',
  'EV Charging Stations': 'EV Charging',
  'Racquetball Court(s)': 'Racquetball Court',
  'Smart Thermostat': 'Smart Thermostats',
  'Smoke Free': 'Smoke-Free',
  'Tennis Court(s)': 'Tennis Court',
  'Wireless Internet Access': 'Wi-Fi',
}

export const highlightOrder = [
  'EV Charging Stations',
  'Tennis Court(s)',
  'Internet Included',
  'Fireplace',
  'Balcony, Patio, Deck',
  'Gated Access',
  'Elevator',
  'Trail, Bike, Hike, Jog',
  'Washer & Dryer Connections',
  'Pet Park',
  'Playground',
  'Hardwood Flooring',
  'Wireless Internet Access',
  'Extra Storage',
  'Stainless Steel Appliances',
  'New/Renovated Interior',
  'Washer & Dryer In Unit',
  'Laundry Facility',
  'Fitness Center',
  'Swimming Pool',
  'Oversized Closets',
  'On Site Maintenance',
  'Emergency Maintenance',
  'Air Conditioning',
]

export const isListWithIcons = false
