export const pmcIdList: Record<string, boolean> = {
  '100008692': true,
  '13652': true,
  '100000033': true,
  '11977': true,
  '13041': true,
  '11912': true,
  '10183': true,
  '10934': true,
  '100014397': true,
  '100014391': true,
}

// Used for opting out of displaying the estimated utility costs on PDP
export const optOutEstimatedUtilitiesCost: Record<string, boolean> = {
  '13652': true, // UDR
}
