import React from 'react'

export function LeadFormDisclaimer() {
  return (
    <span data-tid="sms-consent">
      You also consent to receive text messages at the number you provided from
      Rent., property owners and property managers, including marketing messages
      and through automated means. You don&lsquo;t need to consent as a
      condition of renting any property.
    </span>
  )
}
